export default {
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.isRootHistory = from.fullPath === '/';
		});
	},

	methods: {
		goToPreviousRoute(destination = '/') {
			if (this.isRootHistory) {
				this.$router.push(destination);
			} else {
				this.$router.back();
			}
		},
	},
};
