<template>
  <div class="role-management-member-edit">
    <section class="nav-title">
      <div
        class="back-button"
        @click="goToPreviousRoute({ name: 'role-management' })"
      >
        <icon name="back" size="lg" />
      </div>
      <txt size="heading-2" class="page-title">Tambah Role</txt>
    </section>

    <section class="role-name mb-32">
      <txt size="title-5" class="mb-8">Nama Role</txt>
      <skeleton v-if="loading.list" width="100px" height="24px" />
      <txt v-else size="body-2">{{ role.name }}</txt>
    </section>

    <section class="add-member mb-32">
      <txt size="title-5" class="mb-8">Tambah Member</txt>
      <div class="search-container">
        <bg-field
          class="searchbar"
          :message="error.member ? errorMessage.notFound : ''"
          :error="error.member"
        >
          <search-bar
            v-model="search"
            placeholder="Cari berdasarkan nama lengkap/email"
          />
        </bg-field>
        <bg-button
          size="lg"
          class="ml-8"
          :loading="loading.addMember"
          @click="handleAddMember"
        >
          Tambah
        </bg-button>
      </div>
    </section>

    <section class="member-list mb-32">
      <table class="ss-table">
        <thead>
          <tr>
            <th
              v-for="(header, i) in headers"
              role="columnheader"
              :aria-label="header.text"
              :key="`header-${i}`"
              :class="header.classes"
            >
              <txt size="title-5">{{ header.text }}</txt>
            </th>
          </tr>
        </thead>

        <tbody v-if="loading.list">
          <tr>
            <td><skeleton width="100%" height="24px" /></td>
            <td><skeleton width="100%" height="24px" /></td>
            <td><skeleton width="100%" height="24px" /></td>
          </tr>
          <tr>
            <td><skeleton width="100%" height="24px" /></td>
            <td><skeleton width="100%" height="24px" /></td>
            <td><skeleton width="100%" height="24px" /></td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-if="!lists.length">
            <td colspan="3" class="no-data">
              <txt size="body-2"
                >Belum ada data. Silakan cari dan tambahkan member.</txt
              >
            </td>
          </tr>
          <tr v-else v-for="(list, i) in lists" :key="`member-list-${i}`">
            <td>
              {{ list.name }}
            </td>
            <td>
              {{ list.email }}
            </td>
            <td class="ta-c">
              <div class="ss-table__menu">
                <div
                  role="button"
                  class="menu-activator"
                  @click="removeMember(list.id)"
                >
                  <icon name="delete" title="Remove this member" />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </section>

    <delete-member-modal
      :is-open.sync="isModalDeleteOpen"
      :selected-member-id.sync="selectedMemberId"
      :role-id="roleId"
      @action-success-delete="removeMemberFromList"
      @action-fail-delete="handleFailToDeleteMember"
    />
  </div>
</template>

<script>
import roleManagementApi from '@admin_endpoints/role-management';
import {
  BgField,
  BgButton,
  BgIcon,
  BgText,
  BgSkeleton,
  BgSearchBar,
} from 'bangul-vue';
import previousRouteHandler from '@admin/mixins/previousRouteHandler';

export default {
  name: 'RoleManagementMemberEdit',

  components: {
    BgField,
    BgButton,
    icon: BgIcon,
    txt: BgText,
    skeleton: BgSkeleton,
    SearchBar: BgSearchBar,
    DeleteMemberModal: () => import('./components/DeleteMemberModal'),
  },

  mixins: [previousRouteHandler],

  data() {
    return {
      search: '',
      loading: {
        list: false,
        addMember: false,
      },
      error: {
        member: false,
      },
      errorMessage: {
        notFound: 'Member tidak ditemukan',
        deleteMember: '',
      },
      headers: [
        {
          text: 'Nama Member',
          classes: 'ta-l',
        },
        {
          text: 'Email',
          classes: 'ta-l',
        },
        {
          text: 'Action',
        },
      ],
      lists: [],
      role: {},
      isModalDeleteOpen: false,
      selectedMemberId: null,
    };
  },

  computed: {
    roleId() {
      return this.$route.params.roleId;
    },
  },

  created() {
    this.getMemberList();
  },

  methods: {
    async getMemberList() {
      this.loading.list = true;
      try {
        const { data } = await roleManagementApi.getMemberList(this.roleId);

        if (data.status) {
          this.lists = data.data.member;
          this.role = data.data.role;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading.list = false;
      }
    },

    async getFindMember(postData) {
      const result = { success: false, data: {} };
      try {
        const { data } = await roleManagementApi.addMemberToRole(postData);

        if (data.status) {
          result.success = true;
        }
      } catch (error) {
        console.error(error);
      }
      return result;
    },

    async postMemberToRole(roleId) {
      let result = {};
      try {
        result = await roleManagementApi.postMemberToRole(roleId);
      } catch (error) {
        console.error(error);
      }
      return Promise.resolve(result);
    },

    async handleAddMember() {
      this.error.member = false;
      this.loading.addMember = true;

      const data = {
        email: this.search,
        role_id: this.roleId,
      };

      const { success } = await this.getFindMember(data);

      this.loading.addMember = false;

      if (success) {
        this.getMemberList();
        this.search = '';
      } else {
        this.error.member = true;
      }
    },

    removeMember(memberId) {
      this.selectedMemberId = memberId;
      this.isModalDeleteOpen = true;
    },

    removeMemberFromList(deletedMemberId) {
      this.lists = this.lists.filter(list => list.id !== deletedMemberId);
    },

    handleFailToDeleteMember() {
      this.$toast.show('Gagal saat menghapus member.', { duration: 5 });
    },
  },
};
</script>

<style lang="scss" scoped src="./RoleManagementMemberEdit.scss"></style>
